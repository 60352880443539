/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
/*@import "~bootstrap/dist/css/bootstrap.css";*/

body {
	margin: 8px;  
}


.ogen_icon {
  width: 16px;
  height: 16px;
}

.ogen_icon_bg {
  width: 32px;
  height: 32px;
}

textarea {
height: 90px;

}
